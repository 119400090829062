<template>
    <section class="config-obras-carpetas">
        <div class="row">
            <div class="col-12 col-lg-10">
                <!-- title + addFolder -->
                <titulo-divisor titulo="Carpetas">
                    <el-tooltip content="Crear carpeta" effect="light" visible-arrow>
                        <i class="icon-plus-circle text-general f-30 cr-pointer" @click="addFolder" />
                    </el-tooltip>
                </titulo-divisor>
            </div>
        </div>
        <div class="row">
            <div v-for="carpeta in carpetas" :key="carpeta.id" class="col-12 col-lg-10 mb-3">
                <div class="bg-f9 border br-4 px-2">
                    <div class="d-flex justify-content-between">
                        <div class="d-middle text-muted tres-puntos">
                            <i class="icon-folder f-25" />
                            <p class="f-12 pl-2 tres-puntos"> {{ carpeta.nombre }} </p>
                        </div>
                        <div class="d-middle icon-option">
                            <el-tooltip content="Editar" effect="light" visible-arrow>
                                <i class="icon-pencil f-20 cr-pointer" @click="editFolder(carpeta)" />
                            </el-tooltip>
                            <el-tooltip content="Eliminar" effect="light" visible-arrow>
                                <i class="icon-trash-can-outline f-20 cr-pointer" @click="deleteFolder(carpeta)" />
                            </el-tooltip>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- partials -->
        <modal-add-folder ref="modalAddFolder" @add="handleAdd" @update="handleUpdate" />
        <modal-eliminar ref="deleteFolder" title="Eliminar carpeta" mensaje="¿Está seguro que quiere eliminar esta carpeta?" @delete="handleDelete"/>
    </section>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import { FolderFactory } from '~/domain/entities/configurar/obras/carpetas/factories/FolderFactory'


export default {
    components:{
        modalAddFolder: () => import('./partials/modalAddFolder.vue'),
    },
    data(){
        return{
            toDelete: {},
            foldersList:[
                {
                    id: 0,
                    name: 'Planos'
                },
                {
                    id: 1,
                    name: 'Detalle de producto'
                },
                {
                    id: 2,
                    name: 'Presentación'
                },
                {
                    id: 3,
                    name: 'Especificaciones de materiales'
                },
            ]
        }
    },
    computed: {
        ...mapGetters({
            carpetas: 'configurar/obras/obras/carpetas',  
        }),
    },
    methods:{
        ...mapActions({
            obtenerCarpetas: 'configurar/obras/obras/Action_get_carpetas_obras',
            crearCarpeta: 'configurar/obras/obras/Action_create_carpetas_obras',
            editarCarpeta: 'configurar/obras/obras/Action_edit_carpetas_obras',
            eliminarCarpeta: 'configurar/obras/obras/Action_delete_carpetas_obras',
        }),

        addFolder(){
            this.$refs.modalAddFolder.toggle()
        },
        deleteFolder(folder){
            this.toDelete = folder
            this.$refs.deleteFolder.toggle()
        },
        editFolder(data){
            this.$refs.modalAddFolder.toggle(data)
        },
        async handleAdd(data){
            const folder = FolderFactory.instantiateEmpty()
            folder.setNombre(data)
            await this.crearCarpeta(folder)
        },
        async handleUpdate(data){
            const folder = FolderFactory.instantiateEmpty()

            folder.setId(data.id)
            folder.setNombre(data.name)

            await this.editarCarpeta({id: data.id, payload: folder})
        },
        async handleDelete(){
            await this.eliminarCarpeta({id: this.toDelete.id})
        }
    },
    async mounted(){
        await this.obtenerCarpetas()
    }

}
</script>

<style>

</style>